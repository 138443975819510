<template>
  <div
    class="spaceMembers animationOpacity"
    :class="{ spaceMembersMobile: isMobile }"
  >
    <div class="headerFlex">
      <div class="title" :class="`default-font-${defaultFont}`">
        Membros
        <svg
          width="26"
          height="22"
          viewBox="0 0 26 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24 20V18C23.9993 17.1137 23.7044 16.2528 23.1614 15.5523C22.6184 14.8519 21.8581 14.3516 21 14.13M18 20V18C18 16.9391 17.5786 15.9217 16.8284 15.1716C16.0783 14.4214 15.0609 14 14 14H6C4.93913 14 3.92172 14.4214 3.17157 15.1716C2.42143 15.9217 2 16.9391 2 18V20M17 2.13C17.8604 2.3503 18.623 2.8507 19.1676 3.55231C19.7122 4.25392 20.0078 5.11683 20.0078 6.005C20.0078 6.89317 19.7122 7.75608 19.1676 8.45769C18.623 9.1593 17.8604 9.6597 17 9.88M14 6C14 8.20914 12.2091 10 10 10C7.79086 10 6 8.20914 6 6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6Z"
            stroke="#333333"
            stroke-width="2.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div class="lineDivMember"></div>
    <div class="spaceFilters">
      <div :class="{ spaceSearch: !isMobile, inputSearchMobile: isMobile }">
        <div :class="{ inputSearch: !isMobile, inputSearchMobile: isMobile }">
          <img
            src="@/assets/icons/search.svg"
            @click="getMembers()"
            class="Pointer search"
          />
          <b-form-input
            class="input-busca"
            @change="getMembers()"
            v-model="searchMember"
            placeholder="Pesquise um membro"
          ></b-form-input>
        </div>
        <div v-if="!isMobile">
          <button class="openFilter" @click="clickFilter">
            Mostrar Filtros
          </button>
        </div>
        <div v-if="!isMobile && permission">
          <button class="openFilter" @click="openModalImport">
            Fazer Importação
          </button>
        </div>
        <div v-if="!isMobile && permission">
          <button class="openFilter" :class="{ 'button_disabled': this.registrationLimit == 0 }" @click="openModalManual">
            Cadastro Manual
          </button>
        </div>
        <div v-if="!isMobile">
          <button class="openFilter" @click="exportData()">
            Exportar Dados
          </button>
        </div>
        <div v-if="!isMobile && status_auto.length !== 0">
          <button class="openFilter" @click="openModalCopy('assoc')">
            Associar Alunos
          </button>
        </div>
        <div v-if="!isMobile && status_auto.length !== 0">
          <button class="openFilter" @click="openModalCopy('desassoc')">
            Desassociar Alunos
          </button>
        </div>
      </div>
    </div>
    <div v-if="!isMobile && viewFiltro" class="divFilter animationOpacity2">
      <b-form-group label="Selecionar Curso" label-for="name-category">
        <multiselect
          class="item"
          @open="openCourse"
          placeholder="Cursos"
          selectedLabel=""
          deselectLabel="Pressione Enter"
          tagPlaceholder=""
          selectLabel="Pressione Enter"
          track-by="id"
          @select="addClassCourse"
          @remove="removeCourseFilter"
          :taggable="false"
          :custom-label="customLabelCurso"
          v-model="CourseSelectedFilter"
          :options="AllCourse"
        >
          <template slot="afterList">
            <div v-observe-visibility="reachedEndOfList4" />
          </template>
        </multiselect>
      </b-form-group>
      <b-form-group label="Filtrar por Turmas" label-for="name-category">
        <multiselect
          ref="multiselectRef"
          class="item"
          placeholder="Turmas"
          selectedLabel=""
          deselectLabel="Pressione Enter"
          tagPlaceholder=""
          selectLabel="Pressione Enter"
          track-by="id"
          @select="addClassFFilter"
          @remove="removeClassFilter"
          :multiple="true"
          :taggable="false"
          :custom-label="customLabel"
          v-model="actionClassesSelectedFilter"
          :options="AllClass"
        >
        </multiselect>
      </b-form-group>
      <b-form-group
        label="Filtrar por Progresso (Necessário selecionar um Curso)"
        label-for="name-category"
      >
        <br />
        <vue-slider
          @change="getMembers()"
          :max="100"
          v-model="leadscore"
          class="filter-slider"
          :tooltip="tooltip"
          style="padding: 0px 6px"
          :order="false"
        ></vue-slider>
      </b-form-group>
      <b-form-group label="Filtrar por Assinaturas" label-for="name-category">
        <multiselect
          @open="openSignature"
          class="item"
          placeholder="Assinaturas"
          selectedLabel=""
          deselectLabel="Pressione Enter"
          tagPlaceholder=""
          selectLabel="Pressione Enter"
          track-by="id"
          @select="addAssingFilter"
          @remove="removeAssingFilter"
          :multiple="true"
          :taggable="false"
          :custom-label="customLabel"
          v-model="actionSignaturesSelectedFilter"
          :options="AllSignature"
        >
          <template slot="afterList">
            <div v-observe-visibility="reachedEndOfList" />
          </template>
        </multiselect>
      </b-form-group>
    </div>
    <div class="spaceFilters animationOpacity" v-if="!isMobile">
      <div></div>
      <div class="textTotalMember" v-if="!isMobile">
        Total de Membros: {{ totalMembers }}
      </div>
    </div>
    <div
      :class="{ fundoModal: editMember, hide: !editMember }"
      class="animationOpacity2"
    >
      <div class="contentAdd">
        <svg
          @click="close"
          class="close"
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 1.42603L1 16.426M1 1.42603L16 16.426"
            stroke="#B5B9C5"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <div class="title">Editar Membro</div>
        <div class="space">
          <b-form-group label="E-mail do Membro" label-for="name-category">
            <b-form-input
              v-model="emailMembro"
              disabled
              placeholder="E-mail do Membro"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Permissão" label-for="name-category">
            <b-form-select
              v-model="selectedMember"
              :options="optionsMembers"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="space">
          <button class="btn-salvar" @click="salvarMembro">Salvar</button>
        </div>
      </div>
    </div>
    <div class="spaceTable animationOpacity2">
      <div v-if="mensageToAll" class="mensageToAll animationOpacity2">
        <p class="animationOpacity2" v-if="!selectedAll">
          Todos os {{ status_auto.length }} membros nesta página estão
          selecionados.
          <button @click="selectedAll = true">
            Selecionar todos os {{ totalMembers }}
          </button>
        </p>
        <p class="animationOpacity2" v-else>
          Todos os {{ totalMembers }} membros estão selecionados.
          <button @click="clearSelects">Limpar Seleção</button>
        </p>
      </div>
      <b-table
        :fields="fieldsx"
        show-empty
        small
        stacked="md"
        :items="AllMembers"
        :tbody-tr-class="rowClass"
        :filter-included-fields="filterOn"
        v-if="!isMobile && !loading"
      >
        <template #empty>
          <p class="title text-center-empty">{{ emptyText }}</p>
          <div class="d-flex justify-content-center mt-5">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </template>
        <template #emptyfiltered>
          <p class="title text-center-empty">{{ emptyText }}</p>
          <div class="d-flex justify-content-center mt-5">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </template>
        <template v-slot:head(id)>
          <label class="check">
            <b-form-checkbox-group
              id="checkbox-group-all"
              @change="clickCheckAll"
              class="input-check"
              name="flavour-all"
            >
              <b-form-checkbox value="xx"></b-form-checkbox>
            </b-form-checkbox-group>
          </label>
        </template>
        <template #cell(id)="row">
          <div>
            <b-form-checkbox-group
              :id="`checkbox-group-` + row.item.id"
              v-model="status_auto"
              @change="clickCheck"
              class="input-check checkbody"
              :data-id="row.item.id"
              :name="`flavour-` + row.item.id"
            >
              <b-form-checkbox :value="row.item.id"></b-form-checkbox>
            </b-form-checkbox-group>
          </div>
        </template>

        <template #cell(picture)="row">
          <div class="userPic" v-if="row.item.picture !== null && !isMobile">
            <img :src="row.item.picture" alt="img-member" />
          </div>
          <div
            class="userPic2"
            :style="{
              backgroundColor: randomColor(row.item.name.substr(0, 1)),
            }"
            :class="{ mainColor: row.item.role === 'owner' }"
            v-else-if="!isMobile"
          >
            <span>{{ row.item.name.substr(0, 1) }}</span>
          </div>
        </template>

        <template #cell(name)="row">
          <div
            class="textTable"
            v-if="
              row.item.name === ' ' ||
              row.item.name === '' ||
              row.item.name === null
            "
          >
            {{ row.item.email.split("@")[0] }}
          </div>
          <div class="textTable" v-else>{{ row.item.name }}</div>
        </template>

        <template #cell(email)="row">
          <div class="textTable">{{ row.item.email }}</div>
        </template>

        <template #cell(created_at)="row">
          <div class="textTable">
            {{ row.item.created_at | moment("DD/MM/YY HH:mm") }}
          </div>
        </template>

        <template #cell(member_id)="row">
          <b-progress
            :value="row.item.progress"
            show-value
            :max="100"
            class="spaceProgress"
          ></b-progress>
        </template>

        <template #cell(updated_at)="row">
          <div class="textTable" v-if="row.item.role === 'admin'">
            Administrador
          </div>
          <div class="textTable" v-else-if="row.item.role === 'owner'">
            Proprietário
          </div>
          <div class="textTable" v-else-if="row.item.role === 'student'">
            Estudante
          </div>
          <div
            class="textTable"
            v-else-if="row.item.role === 'student_moderator'"
          >
            Moderador
          </div>
          <div class="textTable" v-else>Sem Permissão</div>
        </template>

        <template #cell(role)="row">
          <div class="flex">
            <div class="editar" v-on:click="gerenciar(row.item)">Gerenciar</div>
          </div>
        </template>
      </b-table>

      <div class="d-flex justify-content-center mt-5" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>

      <b-table
        :fields="fieldsxMobile"
        :stickyColumn="true"
        show-empty
        small
        :items="AllMembers"
        :tbody-tr-class="rowClass"
        :filter-included-fields="filterOn"
        v-if="isMobile && !loading"
      >
        <template #empty>
          <p class="title text-center-empty">{{ emptyText }}</p>
          <div class="d-flex justify-content-center mt-5">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </template>
        <template #emptyfiltered>
          <p class="title text-center-empty">{{ emptyText }}</p>
          <div class="d-flex justify-content-center mt-5">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </template>

        <template #cell(picture)="row">
          <div class="userPic" v-if="row.item.picture !== null && !isMobile">
            <img :src="row.item.picture" alt="img-member" />
          </div>
          <div
            class="userPic2"
            :class="{ mainColor: row.item.role === 'owner' }"
            v-else-if="!isMobile"
          >
            <span>{{ row.item.name.substr(0, 1) }}</span>
          </div>
        </template>

        <template #cell(id)="row">
          <div
            class="textTable"
            v-if="
              row.item.name === ' ' ||
              row.item.name === '' ||
              row.item.name === null
            "
          >
            {{ row.item.email.split("@")[0] }}
          </div>
          <div class="textTable" v-else>{{ row.item.name }}</div>
        </template>

        <template #cell(email)="row">
          <div class="textTable">{{ row.item.email }}</div>
        </template>

        <template #cell(created_at)="row">
          <div class="textTable">
            {{ row.item.created_at | moment("DD/MM/YY HH:mm") }}
          </div>
        </template>

        <template #cell(member_id)="row">
          <b-progress
            :value="row.item.progress"
            show-value
            :max="100"
            class="spaceProgress"
          ></b-progress>
        </template>

        <template #cell(updated_at)="row">
          <div class="textTable" v-if="row.item.role === 'admin'">
            Administrador
          </div>
          <div class="textTable" v-else-if="row.item.role === 'owner'">
            Proprietário
          </div>
          <div class="textTable" v-else-if="row.item.role === 'student'">
            Estudante
          </div>
          <div
            class="textTable"
            v-else-if="row.item.role === 'student_moderator'"
          >
            Moderador
          </div>
          <div class="textTable" v-else>Sem Permissão</div>
        </template>

        <template #cell(role)="row">
          <div class="flex">
            <div class="editar" v-on:click="gerenciar(row.item)">Gerenciar</div>
          </div>
        </template>
      </b-table>

      <div class="d-flex justify-content-center mt-5" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>

      <div class="paginate">
        <div class="text" @click="toPage('prev')">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 6H1M1 6L6 11M1 6L6 1"
              stroke="#333333"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Anterior
        </div>
        <div class="text">{{ pageActualMember }} / {{ lastPage }}</div>
        <div class="text" @click="toPage('next')">
          Próximo
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 6L11 6M11 6L6 1M11 6L6 11"
              stroke="#333333"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
    <b-modal id="modal-member" size="xl" hide-footer>
      <div
        :class="{
          allSpaceModalMember: !isMobile,
          allSpaceModalMemberMobile: isMobile,
        }"
      >
        <div class="columMember">
          <p class="titleList">Dados do Membro</p>
          <div
            :class="{
              gridItensHeader: !isMobile,
              gridItensHeaderMobile: isMobile,
            }"
          >
            <div class="flex">
              <div
                class="userPic picProfile"
                v-if="memberSelected.picture !== null"
              >
                <img :src="memberSelected.picture" alt="img-member" />
              </div>
              <div
                v-else
                class="userPic2 picProfile"
                :style="{
                  backgroundColor: randomColor(
                    memberSelected.name.substr(0, 1)
                  ),
                }"
                :class="{ mainColor: memberSelected.role === 'owner' }"
              >
                <span>{{ memberSelected.name.substr(0, 1) }}</span>
              </div>
              <div class="spaceLeft">
                <div
                  class="name"
                  v-if="
                    memberSelected.name === ' ' ||
                    memberSelected.name === '' ||
                    memberSelected.name === null
                  "
                >
                  {{ memberSelected.email.split("@")[0] }}
                </div>
                <div class="name" v-else>{{ memberSelected.name }}</div>
                <div class="email">{{ memberSelected.email }}</div>
                <div class="email">
                  {{ memberSelected.created_at | moment("DD/MM/YYYY HH:mm") }}
                </div>
                <div class="email">{{ memberSelected.cellphone }}</div>
                <div class="email2">
                  Visto por ultimo: {{ lastSeen(memberSelected.last_seen) }}
                </div>
              </div>
            </div>
            <div>
              <div class="titlePermicao">Permissão do Membro:</div>
              <div class="textPermicao" v-if="memberSelected.role === 'admin'">
                Administrador
              </div>
              <div
                class="textPermicao"
                v-else-if="memberSelected.role === 'owner'"
              >
                Proprietário
              </div>
              <div
                class="textPermicao"
                v-else-if="memberSelected.role === 'student'"
              >
                Estudante
              </div>
              <div
                class="textPermicao"
                v-else-if="memberSelected.role === 'student_moderator'"
              >
                Moderador
              </div>
              <div class="textPermicao" v-else>Sem Permissão</div>
            </div>
            <div>
              <div class="titlePermicao">Limite de Conexões:</div>
              <div class="textPermicao" v-if="memberLimitConect !== null">
                {{ memberLimitConect }} Conexões
              </div>
              <div class="textPermicao" v-else>Padrão do Painel</div>
            </div>
            <div>
              <b-dropdown
                v-if="permission"
                id="dropdown-actions-member"
                text="Ações"
                class="m-md-2"
              >
                <b-dropdown-item class="subMenu" @click="addTurma"
                  >Adicionar Turma</b-dropdown-item
                >
                <b-dropdown-item class="subMenu" @click="openAddSignature"
                  >Adicionar Assinatura</b-dropdown-item
                >
                <b-dropdown-item class="subMenu" @click="alterarLimite()"
                  >Alterar Limite de Conexão</b-dropdown-item
                >
                <b-dropdown-item class="subMenu" @click="editar(memberSelected)"
                  >Editar Permissão</b-dropdown-item
                >
                <b-dropdown-item
                  class="subMenu"
                  @click="novaSenha(memberSelected, 'mail')"
                  >Reenviar Senha por E-mail</b-dropdown-item
                >
                <!-- <b-dropdown-item class="subMenu" @click="novaSenha(memberSelected, 'whatsapp')">Reenviar Senha por WhatsApp</b-dropdown-item> -->
              </b-dropdown>
            </div>
            <!-- <div class="actionsBtn">
            <button class="editar" @click="addTurma">Adicionar Turma</button>
            <button class="editar" @click="openAddSignature">Adicionar Assinatura</button>
            <button class="editar" @click="editar(memberSelected)">Editar Permissão</button>
            <button class="editar" @click="novaSenha(memberSelected)">Reenviar Senha</button>
          </div> -->
          </div>
        </div>
        <div class="columMember">
          <b-tabs content-class="mt-3" v-if="!editarLimite">
            <b-tab title="Lista de Cursos" active>
              <div v-if="!loading">
                <!-- <p class="titleList">Lista de Cursos</p> -->
                <div
                  :class="{
                    spaceListCourse: !isMobile,
                    spaceListCourseMobile: isMobile,
                  }"
                >
                  <p>Curso</p>
                  <p v-if="!isMobile">Turma</p>
                  <p v-if="!isMobile">Entrada</p>
                  <p v-if="!isMobile">Validade</p>
                  <p>Progresso</p>
                  <p v-if="!isMobile && permission">Ações</p>
                </div>
                <div class="heightToListCouses">
                  <span v-if="!addClass">
                    <div
                      :class="{
                        spaceListCourse2: !isMobile,
                        spaceListCourse2Mobile: isMobile,
                      }"
                      v-for="(classe, index) in memberSelectedCourses"
                      :key="index"
                    >
                      <p>{{ classe.course.title }}</p>
                      <p v-if="!isMobile">{{ classe.title }}</p>
                      <p
                        v-if="!isMobile && classe.course.liberated_at !== null"
                      >
                        {{
                          classe.course.liberated_at
                            | moment("DD/MM/YYYY HH:mm")
                        }}
                      </p>
                      <p
                        v-else-if="
                          !isMobile && classe.course.liberated_at === null
                        "
                      >
                        {{ classe.created_at | moment("DD/MM/YYYY HH:mm") }}
                      </p>
                      <p
                        v-if="
                          !isMobile && classe.course.liberated_until !== null
                        "
                      >
                        {{
                          classe.course.liberated_until
                            | moment("DD/MM/YYYY HH:mm")
                        }}
                      </p>
                      <p
                        v-else-if="
                          !isMobile && classe.course.liberated_until === null
                        "
                      >
                        Liberado
                      </p>
                      <div @click="openProgress(classe)">
                        <b-progress
                          :value="classe.course.totalPorcentagem"
                          show-value
                          :max="100"
                          class="spaceProgress"
                        ></b-progress>
                      </div>
                      <p
                        v-if="permission"
                        class="desativar"
                        @click="removeClass(classe, 'get')"
                      >
                        Desativar
                      </p>
                    </div>
                  </span>
                  <div v-if="addClass" class="animationOpacity2">
                    <br />
                    <br />
                    <b-form-group
                      label="Turmas"
                      label-for="name-category"
                      class="positionMultiselect"
                    >
                      <multiselect
                        v-if="addClass"
                        placeholder="Turmas"
                        selectedLabel=""
                        deselectLabel="Pressione Enter"
                        tagPlaceholder=""
                        selectLabel="Pressione Enter"
                        track-by="id"
                        @select="addClassF"
                        @remove="removeClass"
                        :multiple="true"
                        :taggable="false"
                        :custom-label="customLabelAllClass"
                        v-model="actionClassesSelected"
                        :options="AllClass"
                      >
                        <template slot="afterList">
                          <div v-observe-visibility="reachedEndOfList2" />
                        </template>
                      </multiselect>
                      <br />
                      <button class="btnVoltar" @click="closeAddClass">
                        Voltar
                      </button>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center mt-5" v-if="loading">
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </b-tab>

            <b-tab title="Assinaturas">
              <div v-if="!loading">
                <!-- <p class="titleList">Assinaturas</p> -->
                <div
                  :class="{
                    spaceListSignature: !isMobile,
                    spaceListCourseMobile: isMobile,
                  }"
                >
                  <p>Assinatura</p>
                  <p v-if="!isMobile">Criada em</p>
                  <p>Atualizada em</p>
                  <p v-if="!isMobile && permission">Ações</p>
                </div>
                <div class="heightToListCouses">
                  <span v-if="!addSignature">
                    <div
                      :class="{
                        spaceListSignature2: !isMobile,
                        spaceListCourse2Mobile: isMobile,
                      }"
                      v-for="(signature, index) in memberSelectedSignature"
                      :key="index"
                    >
                      <p>{{ signature.title }}</p>
                      <p v-if="!isMobile">
                        {{ signature.created_at | moment("DD/MM/YYYY HH:mm") }}
                      </p>
                      <p>
                        {{ signature.updated_at | moment("DD/MM/YYYY HH:mm") }}
                      </p>
                      <p
                        v-if="permission"
                        class="desativar"
                        @click="removeAssing(signature, 'get')"
                      >
                        Desativar
                      </p>
                    </div>
                  </span>
                  <div v-if="addSignature" class="animationOpacity2">
                    <br />
                    <br />
                    <b-form-group label="Assinaturas" label-for="name-category">
                      <multiselect
                        v-if="addSignature"
                        placeholder="Assinaturas"
                        selectedLabel=""
                        deselectLabel="Pressione Enter"
                        tagPlaceholder=""
                        selectLabel="Pressione Enter"
                        track-by="id"
                        @select="addAssing"
                        @remove="removeAssing"
                        :multiple="true"
                        :taggable="false"
                        :custom-label="customLabel"
                        v-model="actionSignaturesSelected"
                        :options="AllSignature"
                      >
                        <template slot="afterList">
                          <div v-observe-visibility="reachedEndOfList" />
                        </template>
                      </multiselect>
                      <br />
                      <button class="btnVoltar" @click="closeAddAssing">
                        Voltar
                      </button>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center mt-5" v-if="loading">
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </b-tab>

            <b-tab title="Cursos Desativados" @click.once="getUnicMemberDisabled()" v-if="!addClass">
              <div v-if="!loading">
                <!-- <p class="titleList">Lista de Cursos</p> -->
                <div
                  :class="{
                    spaceListCourse: !isMobile,
                    spaceListCourseMobile: isMobile,
                  }"
                >
                  <p>Curso</p>
                  <p v-if="!isMobile">Turma</p>
                  <p v-if="!isMobile">Entrada</p>
                  <p v-if="!isMobile">Validade</p>
                  <p>Progresso</p>
                  <p v-if="!isMobile && permission">Ações</p>
                </div>
                <div class="heightToListCouses">
                  <span v-if="!addClass">
                    <div
                      :class="{
                        spaceListCourse2: !isMobile,
                        spaceListCourse2Mobile: isMobile,
                      }"
                      v-for="(classe, index) in memberSelectedCoursesDisabled"
                      :key="index"
                    >
                      <p>{{ classe.course.title }}</p>
                      <p v-if="!isMobile">{{ classe.title }}</p>
                      <p
                        v-if="!isMobile && classe.course.liberated_at !== null"
                      >
                        {{
                          classe.course.liberated_at
                            | moment("DD/MM/YYYY HH:mm")
                        }}
                      </p>
                      <p
                        v-else-if="
                          !isMobile && classe.course.liberated_at === null
                        "
                      >
                        {{ classe.created_at | moment("DD/MM/YYYY HH:mm") }}
                      </p>
                      <p
                        v-if="
                          !isMobile && classe.course.liberated_until !== null
                        "
                      >
                        {{
                          classe.course.liberated_until
                            | moment("DD/MM/YYYY HH:mm")
                        }}
                      </p>
                      <p
                        v-else-if="
                          !isMobile && classe.course.liberated_until === null
                        "
                      >
                        Liberado
                      </p>
                      <div @click="openProgress(classe)">
                        <b-progress
                          :value="classe.course.totalPorcentagem"
                          show-value
                          :max="100"
                          class="spaceProgress"
                        ></b-progress>
                      </div>
                      <p
                        class="ativar"
                        v-if="permission"
                        @click="reativarClass(classe, 'get')"
                      >
                        Ativar
                      </p>
                    </div>
                  </span>
                  <div v-if="addClass" class="animationOpacity2">
                    <br />
                    <br />
                    <b-form-group label="Turmas" label-for="name-category">
                      <multiselect
                        v-if="addClass"
                        placeholder="Turmas"
                        selectedLabel=""
                        deselectLabel="Pressione Enter"
                        tagPlaceholder=""
                        selectLabel="Pressione Enter"
                        track-by="id"
                        @select="addClassF"
                        @remove="removeClass"
                        :multiple="true"
                        :taggable="false"
                        :custom-label="customLabelAllClass"
                        v-model="actionClassesSelected"
                        :options="AllClass"
                      >
                        <template slot="afterList">
                          <div v-observe-visibility="reachedEndOfList2" />
                        </template>
                      </multiselect>
                      <br />
                      <button class="btnVoltar" @click="closeAddClass">
                        Voltar
                      </button>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center mt-5" v-if="loading">
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </b-tab>

            <b-tab title="Assinaturas Desativas" @click.once="getUnicMemberDisabled()" v-if="!addClass">
              <div v-if="!loading">
                <!-- <p class="titleList">Assinaturas</p> -->
                <div
                  :class="{
                    spaceListSignature: !isMobile,
                    spaceListCourseMobile: isMobile,
                  }"
                >
                  <p>Assinatura</p>
                  <p v-if="!isMobile">Criada em</p>
                  <p>Atualizada em</p>
                  <p v-if="!isMobile && permission">Ações</p>
                </div>
                <div class="heightToListCouses">
                  <span v-if="!addSignature">
                    <div
                      :class="{
                        spaceListSignature2: !isMobile,
                        spaceListCourse2Mobile: isMobile,
                      }"
                      v-for="(
                        signature, index
                      ) in memberSelectedSignatureDesactive"
                      :key="index"
                    >
                      <p>{{ signature.title }}</p>
                      <p v-if="!isMobile">
                        {{ signature.created_at | moment("DD/MM/YYYY HH:mm") }}
                      </p>
                      <p>
                        {{ signature.updated_at | moment("DD/MM/YYYY HH:mm") }}
                      </p>
                      <p
                        v-if="permission"
                        class="ativar"
                        @click="reativarSignature(signature, 'get')"
                      >
                        Ativar
                      </p>
                    </div>
                  </span>
                  <div v-if="addSignature" class="animationOpacity2">
                    <br />
                    <br />
                    <b-form-group label="Assinaturas" label-for="name-category">
                      <multiselect
                        v-if="addSignature"
                        placeholder="Assinaturas"
                        selectedLabel=""
                        deselectLabel="Pressione Enter"
                        tagPlaceholder=""
                        selectLabel="Pressione Enter"
                        track-by="id"
                        @select="addAssing"
                        @remove="removeAssing"
                        :multiple="true"
                        :taggable="false"
                        :custom-label="customLabel"
                        v-model="actionSignaturesSelected"
                        :options="AllSignature"
                      >
                        <template slot="afterList">
                          <div v-observe-visibility="reachedEndOfList" />
                        </template>
                      </multiselect>
                      <br />
                      <button class="btnVoltar" @click="closeAddAssing">
                        Voltar
                      </button>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center mt-5" v-if="loading">
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </b-tab>

            <b-tab title="Campos Adicionais" @click.once="getCustomInputs()">
              <div class="extra" v-if="!loading">
                <div v-for="(find, index) in finds" :key="index">
                  <b-form-group :label="find.name" name-for="name">
                    <b-form-input
                      v-if="find.type === 'text'"
                      v-model="find.value"
                      disabled
                    ></b-form-input>
                    <b-form-textarea
                      v-if="find.type === 'textarea'"
                      v-model="find.value"
                      rows="3"
                      disabled
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
              <div class="d-flex justify-content-center mt-5" v-if="loading">
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </b-tab>

            <b-tab title="Quizzes" @click.once="fetchQuizzes()">
              <div v-if="!loading && quizzes.length !== 0">
                <div class="spaceListQuizz">
                  <p>Aula</p>
                  <p>Pergunta</p>
                  <p>Resposta</p>
                  <p>Está Correto?</p>
                </div>
                <div class="heightToListCouses notPadding">
                  <span v-if="!addClass">
                    <div
                      class="spaceListQuizz2"
                      v-for="(quiz, index) in quizzes"
                      :key="index"
                    >
                      <p>{{ quiz.lesson_title }}</p>
                      <p>{{ quiz.text }}</p>
                      <p>
                        {{ quiz.my_answer.text }}
                        <svg
                          v-if="quiz.my_answer.is_correct === 1"
                          width="13"
                          height="10"
                          viewBox="0 0 13 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.7022 0.289387C12.6162 0.197689 12.5139 0.124912 12.4012 0.0752425C12.2885 0.0255733 12.1676 0 12.0455 0C11.9234 0 11.8025 0.0255733 11.6897 0.0752425C11.577 0.124912 11.4747 0.197689 11.3887 0.289387L4.49736 7.58766L1.60206 4.51574C1.51277 4.42447 1.40738 4.35275 1.29188 4.30462C1.17639 4.25649 1.05306 4.23291 0.92894 4.23516C0.804822 4.23741 0.68234 4.26559 0.568489 4.31793C0.454648 4.37017 0.351656 4.44579 0.265408 4.5402C0.179159 4.63461 0.111347 4.74604 0.0658268 4.86823C0.020316 4.99042 -0.00200475 5.12083 0.000141283 5.25212C0.00228732 5.38332 0.0288629 5.51294 0.078342 5.63328C0.12783 5.75371 0.199251 5.86259 0.288533 5.95387L3.84059 9.71061C3.92658 9.80228 4.0289 9.87507 4.14161 9.92477C4.25434 9.97437 4.37524 10 4.49736 10C4.61947 10 4.74035 9.97437 4.85311 9.92477C4.96577 9.87507 5.06808 9.80228 5.15411 9.71061L12.7022 1.72752C12.7961 1.63591 12.8711 1.52472 12.9223 1.40096C12.9735 1.27721 13 1.14356 13 1.00845C13 0.873345 12.9735 0.739707 12.9223 0.615949C12.8711 0.492192 12.7961 0.381006 12.7022 0.289387Z"
                            fill="#00E4A0"
                          />
                        </svg>
                        <svg
                          v-else
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.17385 4.99927L9.75374 1.42724C9.9105 1.27045 9.99859 1.05779 9.99859 0.836055C9.99859 0.614321 9.9105 0.40167 9.75374 0.244874C9.59697 0.0880859 9.38434 0 9.16264 0C8.94094 0 8.72831 0.0880859 8.57154 0.244874L4.99998 3.82524L1.42846 0.244874C1.27169 0.0880859 1.05906 0 0.83736 0C0.615657 0 0.403028 0.0880859 0.246263 0.244874C0.0894969 0.40167 0.00142358 0.614321 0.00142358 0.836055C0.00142358 1.05779 0.0894969 1.27045 0.246263 1.42724L3.82611 4.99927L0.246263 8.57134C0.168229 8.64878 0.106298 8.74087 0.06403 8.84237C0.0217624 8.94378 0 9.05261 0 9.16252C0 9.27252 0.0217624 9.38134 0.06403 9.48276C0.106298 9.58426 0.168229 9.67635 0.246263 9.7537C0.323655 9.83181 0.415733 9.89375 0.517185 9.93597C0.618637 9.97827 0.727457 10 0.83736 10C0.947262 10 1.05608 9.97827 1.15754 9.93597C1.25899 9.89375 1.35107 9.83181 1.42846 9.7537L4.99998 6.17331L8.57154 9.7537C8.64897 9.83181 8.74105 9.89375 8.84253 9.93597C8.94394 9.97827 9.05275 10 9.16264 10C9.27262 10 9.38143 9.97827 9.48283 9.93597C9.58432 9.89375 9.6764 9.83181 9.75374 9.7537C9.83183 9.67635 9.89377 9.58426 9.93598 9.48276C9.97827 9.38134 10 9.27252 10 9.16252C10 9.05261 9.97827 8.94378 9.93598 8.84237C9.89377 8.74087 9.83183 8.64878 9.75374 8.57134L6.17385 4.99927Z"
                            fill="#FF0C37"
                          />
                        </svg>
                      </p>
                      <p>{{ quiz.correct_answer.text }}</p>
                    </div>
                  </span>
                </div>
              </div>
              <div class="d-flex justify-content-center mt-5" v-if="loading">
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </b-tab>
          </b-tabs>
          <div v-else>
            <div>
              <div class="spaceInputs">
                <b-form-group
                  label="Limite de Conexão"
                  class="textLimiteLabel"
                  label-for="name"
                >
                  <b-form-input
                    v-model="memberLimitConect"
                    @change="updateNumberLimitMember"
                    style="width: 80%"
                    type="range"
                    min="1"
                    max="5"
                    step="1"
                  ></b-form-input>
                  <div
                    class="textLimiteLabel text mt-2"
                    v-if="memberLimitConect === null"
                  >
                    Limite: Sem Configuração
                  </div>
                  <div class="textLimiteLabel text mt-2" v-else>
                    Limite: {{ memberLimitConect }} Usuarios
                  </div>
                </b-form-group>
              </div>
              <div>
                <button class="btnVoltar" @click="alterarLimite">Voltar</button>
                <button class="btnRemoveLimite" @click="deleteLimite">
                  Remover Limite Personalizado
                </button>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-new-import" hide-footer>
      <div class="allContentModal">
        <div>
          <svg
            width="363"
            height="281"
            viewBox="0 0 363 281"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d)">
              <path
                d="M243.627 234.536C243.627 234.158 243.933 233.851 244.311 233.851H249.785C250.163 233.851 250.469 234.158 250.469 234.536V240.01C250.469 240.388 250.163 240.694 249.785 240.694H244.311C243.933 240.694 243.627 240.388 243.627 240.01V234.536Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter1_d)">
              <path
                d="M313.2 184.871C313.35 184.511 313.764 184.34 314.124 184.491L319.344 186.668C319.704 186.818 319.874 187.232 319.724 187.592L317.547 192.811C317.397 193.172 316.983 193.342 316.622 193.192L311.403 191.015C311.043 190.865 310.873 190.451 311.023 190.09L313.2 184.871Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter2_d)">
              <path
                d="M289.178 45.2888C289.047 44.9213 289.238 44.5166 289.605 44.3849L294.929 42.4767C295.296 42.345 295.701 42.5361 295.833 42.9036L297.741 48.227C297.873 48.5945 297.681 48.9992 297.314 49.131L291.991 51.0391C291.623 51.1709 291.218 50.9797 291.087 50.6122L289.178 45.2888Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter3_d)">
              <path
                d="M72.5611 10.6843C72.5611 10.3064 72.8675 10 73.2454 10H78.7195C79.0974 10 79.4037 10.3064 79.4037 10.6843V16.1584C79.4037 16.5363 79.0974 16.8426 78.7195 16.8426H73.2454C72.8675 16.8426 72.5611 16.5363 72.5611 16.1584V10.6843Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter4_d)">
              <path
                d="M77.4487 51.7539C77.4487 51.3683 77.7613 51.0557 78.1469 51.0557H81.6381C82.0237 51.0557 82.3363 51.3683 82.3363 51.7539V55.245C82.3363 55.6306 82.0237 55.9433 81.6381 55.9433H78.1469C77.7613 55.9433 77.4487 55.6306 77.4487 55.245V51.7539Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter5_d)">
              <path
                d="M279.795 217.967C279.795 217.562 280.014 217.234 280.284 217.234H281.261C281.531 217.234 281.75 217.562 281.75 217.967V219.433C281.75 219.838 281.531 220.166 281.261 220.166H280.284C280.014 220.166 279.795 219.838 279.795 219.433V217.967Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter6_d)">
              <path
                d="M100.909 228.719C100.909 228.315 101.128 227.986 101.398 227.986H102.375C102.645 227.986 102.864 228.315 102.864 228.719V230.186C102.864 230.591 102.645 230.919 102.375 230.919H101.398C101.128 230.919 100.909 230.591 100.909 230.186V228.719Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter7_d)">
              <path
                d="M72.5611 136.833C72.5611 136.428 72.8893 136.1 73.2942 136.1H74.7605C75.1654 136.1 75.4937 136.428 75.4937 136.833V138.299C75.4937 138.704 75.1654 139.032 74.7605 139.032H73.2942C72.8893 139.032 72.5611 138.704 72.5611 138.299V136.833Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter8_d)">
              <path
                d="M231.896 35.1711C231.896 34.7662 232.225 34.438 232.63 34.438H234.096C234.501 34.438 234.829 34.7662 234.829 35.1711V36.6374C234.829 37.0423 234.501 37.3705 234.096 37.3705H232.63C232.225 37.3705 231.896 37.0423 231.896 36.6374V35.1711Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter9_d)">
              <path
                d="M290.547 108.485C290.547 108.08 290.876 107.752 291.281 107.752H292.747C293.152 107.752 293.48 108.08 293.48 108.485V109.951C293.48 110.356 293.152 110.684 292.747 110.684H291.281C290.876 110.684 290.547 110.356 290.547 109.951V108.485Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter10_d)">
              <path
                d="M349.198 180.821C349.198 180.416 349.527 180.088 349.932 180.088H351.398C351.803 180.088 352.131 180.416 352.131 180.821V182.287C352.131 182.692 351.803 183.021 351.398 183.021H349.932C349.527 183.021 349.198 182.692 349.198 182.287V180.821Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter11_d)">
              <path
                d="M138.055 24.4184C138.055 24.0135 138.383 23.6853 138.788 23.6853H140.254C140.659 23.6853 140.987 24.0135 140.987 24.4184V25.8847C140.987 26.2896 140.659 26.6179 140.254 26.6179H138.788C138.383 26.6179 138.055 26.2896 138.055 25.8847V24.4184Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter12_d)">
              <path
                d="M185.953 251.167C185.953 250.782 186.266 250.469 186.651 250.469H190.142C190.528 250.469 190.841 250.782 190.841 251.167V254.659C190.841 255.044 190.528 255.357 190.142 255.357H186.651C186.266 255.357 185.953 255.044 185.953 254.659V251.167Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter13_d)">
              <path
                d="M317.918 127.023C317.918 126.637 318.231 126.325 318.616 126.325H322.107C322.493 126.325 322.806 126.637 322.806 127.023V130.514C322.806 130.9 322.493 131.212 322.107 131.212H318.616C318.231 131.212 317.918 130.9 317.918 130.514V127.023Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter14_d)">
              <path
                d="M257.312 15.5858C257.312 15.2002 257.624 14.8876 258.01 14.8876H261.501C261.887 14.8876 262.199 15.2002 262.199 15.5858V19.0769C262.199 19.4626 261.887 19.7752 261.501 19.7752H258.01C257.624 19.7752 257.312 19.4626 257.312 19.0769V15.5858Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter15_d)">
              <path
                d="M39.3255 231.617C39.3255 231.232 39.6381 230.919 40.0237 230.919H43.5149C43.9005 230.919 44.2131 231.232 44.2131 231.617V235.108C44.2131 235.494 43.9005 235.807 43.5149 235.807H40.0237C39.6381 235.807 39.3255 235.494 39.3255 235.108V231.617Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter16_d)">
              <path
                d="M53.5499 165.24C53.3249 164.921 53.4011 164.48 53.7202 164.255L58.3416 160.996C58.6607 160.771 59.1017 160.847 59.3267 161.166L62.5858 165.788C62.8108 166.107 62.7346 166.548 62.4155 166.773L57.7941 170.032C57.475 170.257 57.034 170.181 56.809 169.862L53.5499 165.24Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter17_d)">
              <path
                d="M10.4074 109.428C10.1824 109.109 10.2586 108.668 10.5777 108.443L18.0876 103.147C18.4066 102.922 18.8476 102.998 19.0726 103.317L24.3687 110.827C24.5937 111.146 24.5174 111.587 24.1984 111.812L16.6885 117.108C16.3695 117.333 15.9284 117.257 15.7034 116.938L10.4074 109.428Z"
                fill="#00E4A0"
              />
            </g>
            <g filter="url(#filter18_d)">
              <path
                d="M97.4799 261.452C97.7315 261.153 98.1775 261.115 98.476 261.367L102.8 265.012C103.098 265.263 103.136 265.709 102.885 266.008L99.2399 270.331C98.9883 270.63 98.5423 270.668 98.2438 270.416L93.92 266.771C93.6215 266.52 93.5835 266.074 93.8351 265.775L97.4799 261.452Z"
                fill="#00E4A0"
              />
            </g>
            <path
              class="item"
              d="M219.5 184.625V172.375C219.5 165.877 216.919 159.645 212.324 155.051C207.73 150.456 201.498 147.875 195 147.875H146C139.502 147.875 133.271 150.456 128.676 155.051C124.081 159.645 121.5 165.877 121.5 172.375V184.625M170.5 123.375C184.031 123.375 195 112.406 195 98.875C195 85.344 184.031 74.375 170.5 74.375C156.969 74.375 146 85.344 146 98.875C146 112.406 156.969 123.375 170.5 123.375Z"
              stroke="#00E4A0"
              stroke-width="9"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <defs>
              <filter
                id="filter0_d"
                x="233.627"
                y="223.851"
                width="26.8426"
                height="26.8426"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter1_d"
                x="300.751"
                y="174.219"
                width="29.2452"
                height="29.2452"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter2_d"
                x="278.94"
                y="32.2382"
                width="29.0395"
                height="29.0395"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter3_d"
                x="62.5611"
                y="0"
                width="26.8426"
                height="26.8426"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter4_d"
                x="67.4487"
                y="41.0557"
                width="24.8876"
                height="24.8876"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter5_d"
                x="269.795"
                y="207.234"
                width="21.955"
                height="22.9326"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter6_d"
                x="90.9091"
                y="217.986"
                width="21.955"
                height="22.9326"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter7_d"
                x="62.5611"
                y="126.1"
                width="22.9326"
                height="22.9326"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter8_d"
                x="221.896"
                y="24.438"
                width="22.9326"
                height="22.9326"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter9_d"
                x="280.547"
                y="97.7517"
                width="22.9326"
                height="22.9326"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter10_d"
                x="339.198"
                y="170.088"
                width="22.9326"
                height="22.9326"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter11_d"
                x="128.055"
                y="13.6853"
                width="22.9326"
                height="22.9326"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter12_d"
                x="175.953"
                y="240.469"
                width="24.8876"
                height="24.8876"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter13_d"
                x="307.918"
                y="116.325"
                width="24.8876"
                height="24.8876"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter14_d"
                x="247.312"
                y="4.88757"
                width="24.8876"
                height="24.8876"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter15_d"
                x="29.3255"
                y="220.919"
                width="24.8876"
                height="24.8876"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter16_d"
                x="43.1425"
                y="150.589"
                width="29.8507"
                height="29.8507"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter17_d"
                x="0"
                y="92.7396"
                width="34.7761"
                height="34.7761"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter18_d"
                x="83.3795"
                y="250.911"
                width="29.9607"
                height="29.9607"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <div class="spaceText1">
          <p class="text1">Deseja realizar uma Importação ?</p>
        </div>
        <div class="spaceText2">
          <p class="text2">
            Para Realizar uma Importação é necessario chamar o suporte!
          </p>
        </div>
        <div>
          <button class="button" @click="openImport">Chamar Suporte</button>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-copy" size="lg" hide-footer>
      <div class="modalCopy">
        <div class="title" v-if="typeModalCopy === 'assoc'">
          Associar membros
        </div>
        <div class="title" v-else>Desassociar membros</div>
        <div class="content">
          <span v-if="status_auto.length > 0">
            <p class="text" v-if="!selectedAll">
              Boa! Você selecionou {{ status_auto.length }} membro(s), selecione
              um produto para
              <span v-if="typeModalCopy === 'assoc'">associar</span>
              <span v-else>desassociar</span> a ele(s)!
            </p>
            <p class="text" v-else-if="selectedAll">
              Boa! Você selecionou {{ totalMembers }} membro(s), selecione um
              produto para
              <span v-if="typeModalCopy === 'assoc'">associar</span>
              <span v-else>desassociar</span> a ele(s)!
            </p>
          </span>
          <p class="text" v-else>
            Boa! Você realizou uma filtragem e com isso buscamos alguns membros,
            selecione um produto para
            <span v-if="typeModalCopy === 'assoc'">associar</span>
            <span v-else>desassociar</span> a eles!
          </p>
          <div>
            <img class="" src="@/assets/icons/iconcopy.svg" alt="alert" />
          </div>
        </div>
        <div
          class="contentSelecAllCAllSignaturelasst"
          v-if="typeModalCopy === 'assoc'"
        >
          <b-form-group label="Produtos" label-for="name-category">
            <multiselect
              noOptions="Nenhum Produto Disponível"
              placeholder="Produtos"
              selectedLabel=""
              deselectLabel="Pressione Enter"
              tagPlaceholder=""
              selectLabel="Pressione Enter"
              track-by="id"
              :multiple="false"
              :taggable="false"
              :custom-label="customLabel2"
              v-model="productAssing"
              :options="AllProducts"
            >
              <template slot="afterList">
                <div v-observe-visibility="reachedEndOfList3" />
              </template>
            </multiselect>
          </b-form-group>
        </div>
        <div class="contentSelect" v-else>
          <b-form-group label="Tipo de Desassociação" label-for="name-category">
            <multiselect
              noOptions="Sem Metodo"
              placeholder="Selecione um Metodo"
              selectedLabel=""
              deselectLabel="Pressione Enter"
              :close-on-select="true"
              selectLabel="Pressione Enter"
              track-by="id"
              :multiple="false"
              :taggable="true"
              v-model="typeCopy"
              :options="optionsTypeCopy"
            ></multiselect>
          </b-form-group>
          <b-form-group
            v-if="typeCopy === 'Turma/Curso'"
            label="Turma/Curso"
            label-for="name-category"
          >
            <multiselect
              @open="openCourse"
              noOptions="Nenhuma Assinatura Disponível"
              placeholder="Turma/Curso"
              selectedLabel=""
              deselectLabel="Pressione Enter"
              tagPlaceholder=""
              selectLabel="Pressione Enter"
              track-by="id"
              :multiple="false"
              :taggable="false"
              :custom-label="customLabelAllClass"
              v-model="courseClassDesassoc"
              :options="AllClass"
            >
              <template slot="afterList">
                <div v-observe-visibility="reachedEndOfList2" />
              </template>
            </multiselect>
          </b-form-group>
          <b-form-group
            v-if="typeCopy === 'Assinatura'"
            label="Assinatura"
            label-for="name-category"
          >
            <multiselect
              @open="openSignature"
              placeholder="Assinaturas"
              selectedLabel=""
              deselectLabel="Pressione Enter"
              tagPlaceholder=""
              selectLabel="Pressione Enter"
              track-by="id"
              :multiple="false"
              :taggable="false"
              :custom-label="customLabel"
              v-model="signatureDesassoc"
              :options="AllSignature"
            >
              <template slot="afterList">
                <div v-observe-visibility="reachedEndOfList" />
              </template>
            </multiselect>
          </b-form-group>
        </div>

        <div class="footer">
          <button class="closeAssoc" @click="closeModalCopy">Cancelar</button>
          <button class="confirm" @click="assingMembers">Confirmar</button>
        </div>
      </div>
    </b-modal>
    <ModalCourseProgress
      :courseAllProgress="courseAllProgress"
    ></ModalCourseProgress>
    <ModalCadastroManual @close="getMembers" :registrationLimit="registrationLimit" @updateLimit="getManualRegistrationLimit()"/>
  </div>
</template>

<script>
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();
import ClassService from "@/services/resources/ClassService";
const serviceClass = ClassService.build();
import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();
import "vue-slider-component/theme/antd.css";
import SignatureService from "@/services/resources/SignatureService";
const serviceSignature = SignatureService.build();
import MemberMetaService from "@/services/resources/MemberMetaService";
const serviceMemberMeta = MemberMetaService.build();
import notify from "@/services/libs/notificacao";
import moment from "moment";
import Cookie from "js-cookie";
import VueSlider from "vue-slider-component";
import Multiselect from "vue-multiselect";
import ModalCourseProgress from "@/components/ModalCourseProgress.vue";
import ModalCadastroManual from "@/components/ModalCadastroManual.vue";
export default {
  name: "MembersComponentV2",
  components: {
    VueSlider,
    Multiselect,
    ModalCourseProgress,
    ModalCadastroManual,
  },
  data() {
    return {
      registrationLimit: 0,
      permission: false,
      AllMembers: [],
      rowClass: "row-table-body",
      emptyText: "Aguardando Resultados",
      filterOn: [],
      finds: [],
      fieldsx: [
        { key: "id", label: "" },
        { key: "picture", label: "" },
        { key: "name", label: "Nome" },
        { key: "email", label: "Email" },
        { key: "created_at", label: "Data de Cadastro" },
        { key: "member_id", label: "Progresso" },
        { key: "updated_at", label: "Permissão" },
        { key: "role", label: "Ações" },
      ],
      fieldsxMobile: [
        { key: "id", label: "Nome" },
        { key: "member_id", label: "Progresso" },
        { key: "role", label: "Ações" },
      ],
      totalMembers: "",
      pageActual: 1,
      lastPage: 1,
      firstPage: 1,
      pageActualMember: 1,
      editMember: false,
      selectedMember: null,
      optionsMembers: [
        { value: "admin", text: "Administrador" },
        { value: "student", text: "Estudante" },
        { value: "student_moderator", text: "Moderador" },
      ],
      id_edit: "",
      emailMembro: "",
      memberSelected: { email: "lucas@teste.com", name: "l" },
      memberSelectedCourses: [],
      memberSelectedCoursesDisabled: [],
      memberSelectedSignature: {},
      disabledDataLoading: false,
      memberSelectedSignatureDesactive: {},
      searchMember: "",
      leadscore: [0, 100],
      tooltip: "always",
      client: {
        width: 0,
      },
      addClass: false,
      addSignature: false,
      pageActualSignature: 1,
      lastPageSignature: 1,
      AllSignature: [],
      actionSignaturesSelected: null,
      pageActualCourse: 1,
      lastPageCourse: 1,
      AllCourse: [],
      pageActualClass: 1,
      lastPageClass: 1,
      AllClass: [],
      actionClassesSelected: null,
      viewFiltro: false,
      currentSiteId: null,
      memberLimitConect: null,
      editarLimite: false,
      status_auto: [],
      memberInPage: 1,
      actionClassesSelectedFilter: [],
      actionClassesSelectedFilterArray: [],
      actionSignaturesSelectedFilter: [],
      actionSignaturesSelectedFilterArray: [],
      CourseSelectedFilter: "",
      productAssing: null,
      AllProducts: [],
      pageActualProduct: 1,
      lastPageProduct: 1,
      mensageToAll: false,
      selectedAll: false,
      courseAllProgress: [
        {
          course: {
            title: "",
          },
        },
      ],
      typeModalCopy: "",
      optionsTypeCopy: ["Turma/Curso", "Assinatura"],
      typeCopy: null,
      signatureDesassoc: "",
      courseClassDesassoc: "",
      quizzes: [],
      loading: false,
    };
  },
  computed: {
    currentSite() { 
      return this.$store.getters.currentSite;
    },
    isMobile() {
      return this.client.width <= 576;
    },
    defaultFont() {
      return this.$store.state.metas.metas.default_font;
    },
  },
  created() {
    this.verifyUser();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    verifyUser() {
      const perm = Cookie.get("access_level");
      if (perm === "owner" || perm === "admin") {
        this.permission = true;
      }
    },
    async getManualRegistrationLimit() {
      let resp = await this.$store.dispatch("fetchMetas", ["manual_registration_limit"]);
      this.registrationLimit = resp.manual_registration_limit
    },
    fetchQuizzes() {
      serviceMember
        .read(this.memberSelected.id + "/answer-question")
        .then((resp) => {
          //console.log("get answer-question", resp);
          this.quizzes = resp;
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    openSignature() {
      this.pageActualSignature = 1;
      this.AllSignature = [];
      this.getAllSignature();
    },
    openCourse() {
      this.pageActualClass = 1;
      this.pageActualCourse = 1;
      this.AllCopageActualCourse = [];
      this.AllClass = [];
      this.getAllCourse();
      this.getAllClass();
    },
    openProgress(dataCourse) {
      //console.log('xxxxxxxxxxxxx');
      //console.log(dataCourse);
      //console.log('xxxxxxxxxxxxx');

      this.loading = true;
      serviceMember
        .read(
          this.memberSelected.id +
            "/course/" +
            dataCourse.course.id +
            "/details"
        )
        .then((resp) => {
          //console.log("get unic member details", resp);
          this.courseAllProgress = [dataCourse, resp];
          //console.log(this.courseAllProgress);
          this.$root.$emit("bv::show::modal", "modal-list-lesson", "#btnShow");
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    lastSeen(myDate) {
      let date1 = moment().format("DD/MM/YYYY HH:mm").valueOf();
      let date2 = moment(myDate).format("DD/MM/YYYY HH:mm").valueOf();
      var isAfter = moment(date2).isAfter(date1);
      if (isAfter) {
        return "há alguns segundos";
      } else {
        let minutes = [15, 60, 180, 1440];
        for (let i = 0; i < minutes.length; i++) {
          const minute = minutes[i];
          const PARSE_FORMAT = "DD/MM/YYYY HH:mm";
          const calc = moment().subtract(minute, "minutes");
          const result = moment(myDate, PARSE_FORMAT).isAfter(calc);
          if (result === true) {
            if (minute === 15) {
              return "há alguns minutos";
            } else if (minute === 60) {
              return "há uma hora atrás";
            } else if (minute === 180) {
              return "há mais de uma hora atrás";
            } else if (minute === 1440) {
              return "há um dia atrás";
            }
          } else {
            var now = moment(new Date());
            var end = moment(myDate);
            var duration = moment.duration(now.diff(end));
            var days = duration.asDays();
            return "há " + parseInt(days, 10) + " dias";
          }
        }
      }
    },
    randomColor(letra) {
      letra = letra.toLowerCase();
      const cores = [
        "#00E4A0",
        "#2133D2",
        "#FF0C37",
        "#FFCB37",
        "#2ED7EE",
        "#5D21D2",
        "#5A68DF",
        "#FF37D3",
      ];
      if (letra == "a" || letra == "c" || letra == "e") {
        return cores[1];
      } else if (letra == "a" || letra == "c" || letra == "d" || letra == "w") {
        return cores[2];
      } else if (letra == "e" || letra == "f" || letra == "g" || letra == "x") {
        return cores[3];
      } else if (letra == "h" || letra == "i" || letra == "j" || letra == "y") {
        return cores[4];
      } else if (letra == "k" || letra == "l" || letra == "v" || letra == "z") {
        return cores[5];
      } else if (letra == "n" || letra == "o" || letra == "p") {
        return cores[6];
      } else if (letra == "q" || letra == "r" || letra == "s") {
        return cores[7];
      } else if (letra == "t" || letra == "u" || letra == "m") {
        return cores[0];
      }
    },
    clearSelects() {
      document.getElementById("checkbox-group-all").click();
      this.selectedAll = false;
    },
    assingMembers() {
      if (this.selectedAll === true) {
        var dadosCourse = [];
        var dadosAssing = [];
        if (this.actionClassesSelectedFilterArray.length !== 0) {
          dadosCourse = this.actionClassesSelectedFilterArray;
        }
        if (this.actionSignaturesSelectedFilterArray.length !== 0) {
          dadosAssing = this.actionSignaturesSelectedFilterArray;
        }

        var type = "";
        var item_id = "";
        var item_id_course = "";
        var item_id_signature = "";
        if (this.typeModalCopy === "assoc") {
          type = "associate_to_product";
          item_id = this.productAssing.id;
        } else {
          if (this.typeCopy === "Turma/Curso") {
            type = "remove_from_course_class";
            item_id_course = this.courseClassDesassoc.id;
          } else {
            type = "remove_from_signature";
            item_id_signature = this.signatureDesassoc.id;
            if (!dadosAssing.length) {
              dadosAssing = [item_id_signature];
            }
          }
        }

        //console.log('associar com base no filtro');
        this.loading = true;
        let data = {
          id: "copy",
          product_id: item_id,
          course_class_id: item_id_course,
          signature_id: item_id_signature,
          course_classes: dadosCourse,
          signatures: dadosAssing,
          type: type,
          minimum_global_progress: this.leadscore[0],
          maximum_global_progress: this.leadscore[1],
        };
        serviceMember
          .postID(data)
          .then((resp) => {
            //console.log("assoc members", resp);
            if (this.typeModalCopy === "assoc") {
              notify("sucesso", "Membros Associados!");
            } else {
              notify("sucesso", "Membros Desassociados!");
            }
            this.$root.$emit("bv::hide::modal", "modal-copy", "#btnShow");
            this.loading = false;
          })
          .catch((err) => {
            //console.log(err);
            this.loading = false;
          });
      } else {
        //console.log('associar com base no selecionados');
        var type = "";
        var item_id = "";
        var item_id_course = "";
        var item_id_signature = "";
        if (this.typeModalCopy === "assoc") {
          type = "associate_to_product";
          item_id = this.productAssing.id;
        } else {
          if (this.typeCopy === "Turma/Curso") {
            type = "remove_from_course_class";
            item_id_course = this.courseClassDesassoc.id;
          } else {
            type = "remove_from_signature";
            item_id_signature = this.signatureDesassoc.id;
          }
        }

        //console.log('associar com base no filtro');
        this.loading = true;
        let data = {
          id: "copy",
          product_id: item_id,
          member_ids: this.status_auto,
          course_class_id: item_id_course,
          signature_id: item_id_signature,
          type: type,
        };
        serviceMember
          .postID(data)
          .then((resp) => {
            //console.log("assoc members", resp);
            if (this.typeModalCopy === "assoc") {
              notify("sucesso", "Membros Associados!");
            } else {
              notify("sucesso", "Membros Desassociados!");
            }
            this.$root.$emit("bv::hide::modal", "modal-copy", "#btnShow");
            this.loading = false;
          })
          .catch((err) => {
            //console.log(err);
            this.loading = false;
          });
      }
    },
    getAllProducts(action) {
      this.loading = true;
      if (this.pageActualProduct === null) {
        this.pageActualProduct = 1;
      }
      var url = "product?page=" + this.pageActualProduct;
      serviceMember
        .read(url)
        .then((resp) => {
          //console.log("todos os produtos", resp);
          var data = resp.data;
          this.pageActualProduct = resp.current_page;
          this.lastPageProduct = resp.last_page;
          if (action === "push") {
            let list = resp.data;
            for (let i = 0; i < list.length; i++) {
              const element = list[i];
              this.AllProducts.push(element);
            }
          } else {
            this.AllProducts = data;
          }
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    reachedEndOfList3(reached) {
      if (reached) {
        this.addNextPageProducts();
      }
    },
    addNextPageProducts() {
      if (this.lastPageProduct >= this.pageActualProduct) {
        this.pageActualProduct = this.pageActualProduct + 1;
        this.getAllProducts("push");
      }
    },
    closeModalCopy() {
      this.$root.$emit("bv::hide::modal", "modal-copy", "#btnShow");
    },
    openModalCopy(type) {
      this.typeModalCopy = type;
      this.$root.$emit("bv::show::modal", "modal-copy", "#btnShow");
    },
    addAssingFilter(selectedOption) {
      //console.log(selectedOption);
      let array = this.actionSignaturesSelectedFilterArray;
      array.push(selectedOption.id);
      //console.log(this.actionSignaturesSelectedFilterArray);
      this.getMembers();
    },
    removeAssingFilter(selectedOption) {
      //console.log(selectedOption);
      let array = this.actionSignaturesSelectedFilterArray;
      this.removeA(array, selectedOption.id);
      //console.log(this.actionSignaturesSelectedFilterArray);
      this.getMembers();
    },
    addClassCourse(selectedOption) {
      //console.log(selectedOption);
      this.CourseSelectedFilter = selectedOption;
      this.getMembers();
    },
    removeCourseFilter() {
      this.CourseSelectedFilter = "";
      this.getMembers();
    },
    addClassFFilter(selectedOption) {
      //console.log(selectedOption);
      let array = this.actionClassesSelectedFilterArray;
      array.push(selectedOption.id);
      //console.log(this.actionClassesSelectedFilterArray);
      this.getMembers();
    },
    removeClassFilter(selectedOption) {
      //console.log(selectedOption);
      let array = this.actionClassesSelectedFilterArray;
      this.removeA(array, selectedOption.id);
      //console.log(this.actionClassesSelectedFilterArray);
      this.getMembers();
    },
    removeA(arr) {
      var what,
        a = arguments,
        L = a.length,
        ax;
      while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1);
        }
      }
      return arr;
    },
    clickCheck(e) {
      this.status_auto = e;
    },
    clickCheckAll() {
      if (this.lastPage > 1) {
        this.mensageToAll = true;
      }
      if (this.status_auto.length === this.memberInPage) {
        this.status_auto = [];
        this.mensageToAll = false;
        this.selectedAll = false;
      } else {
        var array = [];
        let checkbox = document.querySelectorAll(".checkbody");
        for (let i = 0; i < checkbox.length; i++) {
          const ids = checkbox[i].getAttribute("data-id");
          parseInt(ids, 10);
          array.push(ids);
        }
        this.status_auto = array;
      }
    },
    openModalManual() {
      if (this.registrationLimit == 0) {
        this.errorModalManual();
        return; 
      }
      this.$bvModal.show("modal-cadastro-manual");
    },
    errorModalManual() {
      this.$bvToast.toast("Limite de cadastros manuais atingido, entre em contato com o suporte", {
        title: "Limite atingido",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    openModalImport() {
      this.$root.$emit("bv::show::modal", "modal-new-import", "#btnShow");
    },
    openImport() {
      this.$root.$emit("bv::hide::modal", "modal-new-import", "#btnShow");
      window.$crisp.push(["do", "chat:open"]);
      window.$crisp.push([
        "do",
        "message:send",
        ["text", `Olá! Preciso de ajuda para realizar uma importação.`],
      ]);
    },
    deleteLimite() {
      let data = {
        id:
          "/site/" +
          this.memberSelected.id +
          "/" +
          this.currentSiteId +
          "/number_limit_connect",
      };
      this.loading = true;
      serviceMemberMeta
        .destroy(data)
        .then((resp) => {
          //console.log("update number_limit_connect", resp);
          this.loading = false;
          this.buscarLimiteConexoes(this.memberSelected.id);
          this.alterarLimite();
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    alterarLimite() {
      if (this.editarLimite === true) {
        this.editarLimite = false;
      } else {
        this.editarLimite = true;
      }
    },
    updateNumberLimitMember(number) {
      let data = {
        id:
          "/site/" +
          this.memberSelected.id +
          "/" +
          this.currentSiteId +
          "/number_limit_connect",
        value: number,
      };
      this.loading = true;
      serviceMemberMeta
        .postID(data)
        .then((resp) => {
          //console.log("update number_limit_connect", resp);
          this.loading = false;
          this.buscarLimiteConexoes(this.memberSelected.id);
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    getCurrentSiteAndMember() {
      this.currentSiteId = this.currentSite.currentSite.id;
    },
    buscarLimiteConexoes(memberId) {
      let data = {
        id:
          "/site/" +
          memberId +
          "/" +
          this.currentSiteId +
          "?keys[]=number_limit_connect",
      };
      serviceMemberMeta
        .read(data)
        .then((resp) => {
          //console.log("get meta member site limite", resp);
          this.memberLimitConect = resp.number_limit_connect;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    novaSenha(dados, type) {
      this.loading = true;
      let data = {
        id: "/send/password-recovery",
        email: this.memberSelected.email,
        type: type,
      };
      serviceMember
        .postID(data)
        .then((resp) => {
          //console.log('recover passaword', resp);
          notify("sucesso", resp);
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
          var error = JSON.parse(err.response.data);
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }
        });
    },
    clickFilter() {
      if (this.viewFiltro === false) {
        this.viewFiltro = true;
        this.getAllClass();
        this.getAllSignature();
      } else {
        this.viewFiltro = false;
      }
    },
    addTurma() {
      document.getElementsByClassName("nav-link")[0].click();
      this.addClass = true;
      this.pageActualClass = 1;
      this.getAllClass();
    },
    closeAddClass() {
      this.addClass = false;
      this.getUnicMember();
    },
    reachedEndOfList4(reached) {
      if (reached) {
        this.addNextPageAssing4();
      }
    },
    reachedEndOfList2(reached) {
      if (reached) {
        this.addNextPageAssing2();
      }
    },
    addNextPageAssing4() {
      //console.log('sssssssssssssssssssssssss', this.pageActualCourse+1)
      if (this.lastPageCourse >= this.pageActualCourse) {
        //console.log('sssssssssssssssssssssssss', this.pageActualCourse+1)
        this.pageActualCourse = this.pageActualCourse + 1;
        this.getAllCourse("push");
      }
    },
    addNextPageAssing2() {
      //console.log('sssssssssssssssssssssssss', this.pageActualClass+1)
      if (this.lastPageClass >= this.pageActualClass) {
        //console.log('sssssssssssssssssssssssss', this.pageActualClass+1)
        this.pageActualClass = this.pageActualClass + 1;
        this.getAllClass("push");
      }
    },
    reativarSignature(selectedOption, get) {
      let data = {
        id: "restore-signature",
        signature_student_id: selectedOption.pivot.id,
      };
      serviceMember
        .postID(data)
        .then((resp) => {
          //console.log('restore class', resp);
          if (get) {
            this.getUnicMember();
          }
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    reativarClass(selectedOption, get) {
      let data = {
        id: "restore-course-class",
        course_class_student_id: selectedOption.pivot.id,
      };
      serviceMember
        .postID(data)
        .then((resp) => {
          //console.log('restore class', resp);
          if (get) {
            this.getUnicMember();
          }
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    addClassF(selectedOption, get) {
      //console.log(selectedOption, 'xxxxxxxx class')
      this.loading = true;
      let data = {
        id: "add-course-class",
        member_id: this.memberSelected.id,
        course_class_ids: [selectedOption.id],
      };
      serviceMember
        .postID(data)
        .then((resp) => {
          //console.log('add class', resp);
          if (get) {
            this.getUnicMember();
          }
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    removeClass(removedOption, get) {
      //console.log('to remove class', removedOption);
      this.loading = true;
      let data = {
        id: "/remove-course-class",
        course_class_student_id: removedOption.pivot.id,
      };
      serviceMember
        .postID(data)
        .then((resp) => {
          //console.log("delete class", resp);
          if (get) {
            this.getUnicMember();
          }
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    getAllCourse(action) {
      if (action !== "push") {
        this.loading = true;
      }
      if (this.pageActualCourse === null) {
        this.pageActualCourse = 1;
      }
      var url = "page=" + this.pageActualCourse;
      serviceCourse
        .search(url)
        .then((resp) => {
          //console.log("todos os cursos", resp);
          var data = resp.data;
          this.pageActualCourse = resp.current_page;
          this.lastPageCourse = resp.last_page;
          if (action === "push") {
            let list = resp.data;
            for (let i = 0; i < list.length; i++) {
              const element = list[i];
              this.AllCourse.push(element);
            }
          } else {
            this.AllCourse = data;
          }
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    getAllClass(action) {
      if (action !== "push") {
        this.loading = true;
      }
      if (this.pageActualClass === null) {
        this.pageActualClass = 1;
      }
      var url = "page=" + this.pageActualClass;
      serviceClass
        .search(url)
        .then((resp) => {
          //console.log("todas as classes", resp);
          var data = resp.data;
          this.pageActualClass = resp.current_page;
          this.lastPageClass = resp.last_page;
          if (action === "push") {
            let list = resp.data;
            for (let i = 0; i < list.length; i++) {
              const element = list[i];
              this.AllClass.push(element);
            }
          } else {
            this.AllClass = data;
          }
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        })
        .finally(() => {
          const multiselectDiv = this.$refs.multiselectRef.$el.querySelector('.multiselect__content');
          if (multiselectDiv) {     
            multiselectDiv.addEventListener('scroll', (event) => this.handleScrollClass(event));
          }
        });
    },
    reachedEndOfList(reached) {
      if (reached) {
        this.addNextPageAssing();
      }
    },
    addNextPageAssing() {
      if (this.lastPageSignature >= this.pageActualSignature) {
        this.pageActualSignature = this.pageActualSignature + 1;
        this.getAllSignature("push");
      }
    },
    addAssing(selectedOption) {
      //console.log(selectedOption, 'xxxxxxxx')
      this.loading = true;
      let data = {
        id: "add-signature",
        member_id: this.memberSelected.id,
        signature_ids: [selectedOption.id],
      };
      serviceMember
        .postID(data)
        .then((resp) => {
          //console.log('add assing', resp);
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    removeAssing(removedOption, get) {
      //console.log('to remove assing', removedOption);
      this.loading = true;
      let data = {
        id: "/remove-signature",
        signature_student_id: removedOption.pivot.id,
      };
      serviceMember
        .postID(data)
        .then((resp) => {
          //console.log("delete assing", resp);
          if (get) {
            this.getUnicMember();
          }
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    closeAddAssing() {
      this.addSignature = false;
      this.getUnicMember();
    },
    openAddSignature() {
      document.getElementsByClassName("nav-link")[1].click();
      this.addSignature = true;
      this.pageActualSignature = 1;
      this.getAllSignature();
    },
    customLabel({ title }) {
      return `${title}`;
    },
    customLabel2({ name }) {
      return `${name}`;
    },
    customLabelCurso({ title, course }) {
      return `${title}`;
    },
    customLabelAllClass({ title, course }) {
      return `${title + " - " + course.title}`;
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    getAllSignature(action) {
      this.loading = true;
      if (this.pageActualSignature === null) {
        this.pageActualSignature = 1;
      }

      var url = "page=" + this.pageActualSignature;
      serviceSignature
        .search(url)
        .then((resp) => {
          //console.log("todas as assinaturas", resp);
          var data = resp.data;
          this.pageActualSignature = resp.current_page;
          this.lastPageSignature = resp.last_page;
          if (action === "push") {
            let list = resp.data;
            for (let i = 0; i < list.length; i++) {
              const element = list[i];
              this.AllSignature.push(element);
            }
          } else {
            this.AllSignature = data;
          }
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    salvarMembro() {
      var data = {
        id: this.id_edit + "/role",
        role: this.selectedMember,
      };
      this.loading = true;
      serviceMember
        .postID(data)
        .then((resp) => {
          //console.log("update member", resp);
          this.memberSelected.role = this.selectedMember;
          this.loading = false;
          this.close();
          this.getMembers();
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    gerenciar(data) {
      this.memberSelected = data;
      this.getUnicMember();
      this.buscarLimiteConexoes(data.id);
      this.$root.$emit("bv::show::modal", "modal-member", "#btnShow");
    },
    getUnicMemberDisabled() {
      if (this.disabledDataLoading == true) {
        return;
      }
      this.loading = true;
      serviceMember
        .read(
          this.memberSelected.id + "/course-class-and-signatures?inactive=true"
        )
        .then((resp) => {
          //console.log("get unic member disableds", resp);
          let courses = resp.course_class;
          for (let i = 0; i < courses.length; i++) {
            const course = courses[i].course;
            var result = parseInt(course.percentage_complete, 10)
            course.totalPorcentagem = result;
          }
          this.memberSelectedCoursesDisabled = resp.course_class;
          this.memberSelectedSignatureDesactive = resp.signatures;
          this.loading = false;
          this.disabledDataLoading = true;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    getUnicMember() {
      this.loading = true;
      serviceMember
        .read(this.memberSelected.id + "/course-class-and-signatures")
        .then((resp) => {
          let courses = resp.course_class;
          for (let i = 0; i < courses.length; i++) {
            const course = courses[i].course;
            var result = parseInt(course.percentage_complete, 10)
            course.totalPorcentagem = result;
          }

          this.memberSelectedCourses = resp.course_class;
          this.actionClassesSelected = resp.course_class;
          this.memberSelectedSignature = resp.signatures;
          this.actionSignaturesSelected = resp.signatures;
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          notify(
            "erro",
            "Houve um erro ao buscar suas informações, aguarde um instante e tente novamente. 😕"
          );
          this.loading = false;
        });
    },
    editar(data) {
      this.emailMembro = data.email;
      this.id_edit = data.id;
      this.selectedMember = data.role;
      this.editMember = true;
    },
    close() {
      this.editMember = false;
    },
    deletar(id) {
      //console.log('id to delete member', id)
    },
    toPage(action) {
      if (action === "prev") {
        if (this.pageActual !== 1) {
          this.pageActual = this.pageActual - 1;
        }
      } else {
        if (this.lastPage > this.pageActual) {
          this.pageActual = this.pageActual + 1;
        }
      }
      this.getMembers();
    },
    transformRequestOptions(params, type) {
      let options = "";
      for (const key in params) {
        if (typeof params[key] !== "object" && params[key]) {
          options += `${type + "[]"}=${params[key]}&`;
        } else if (
          typeof params[key] === "object" &&
          params[key] &&
          params[key].length
        ) {
          params[key].forEach((el) => {
            options += `${type + "[]"}=${el}&`;
          });
        }
      }
      return options ? options.slice(0, -1) : options;
    },
    getMembers() {
      this.loading = true;
      var url = "";
      var course = "";
      if (
        this.CourseSelectedFilter.id !== "" &&
        this.CourseSelectedFilter !== ""
      ) {
        course = "&course=" + this.CourseSelectedFilter.id;
      }
      if (this.viewFiltro) {
        var dadosCourse = "";
        var dadosAssing = "";
        if (this.actionClassesSelectedFilterArray.length !== 0) {
          dadosCourse =
            "&" +
            this.transformRequestOptions(
              this.actionClassesSelectedFilterArray,
              "course_classes"
            );
        }
        if (this.actionSignaturesSelectedFilterArray.length !== 0) {
          dadosAssing =
            "&" +
            this.transformRequestOptions(
              this.actionSignaturesSelectedFilterArray,
              "signatures"
            );
        }
        if (this.searchMember === "" || this.searchMember === null) {
          url =
            "page=" +
            this.pageActual +
            course +
            "&minimum_global_progress=" +
            this.leadscore[0] +
            "&maximum_global_progress=" +
            this.leadscore[1] +
            dadosAssing +
            dadosCourse;
        } else {
          this.pageActual = 1;
          url =
            "page=" +
            this.pageActual +
            course +
            "&search_string=" +
            this.searchMember +
            "&minimum_global_progress=" +
            this.leadscore[0] +
            "&maximum_global_progress=" +
            this.leadscore[1] +
            dadosAssing +
            dadosCourse;
        }
      } else {
        if (this.searchMember === "" || this.searchMember === null) {
          url = "page=" + this.pageActual + course;
        } else {
          this.pageActual = 1;
          url =
            "page=" +
            this.pageActual +
            course +
            "&search_string=" +
            this.searchMember;
        }
      }
      serviceMember
        .search(url)
        .then((resp) => {
          //console.log("get members", resp);
          this.AllMembers = resp.data;
          this.totalMembers = resp.total;
          this.memberInPage = resp.data.length;
          this.status_auto = [];
          this.pageActualMember = resp.current_page;
          this.lastPage = resp.last_page;
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    getCustomInputs() {
      let data = {
        id: "/site/" + this.memberSelected.id + "/" + this.currentSiteId + "/custom-fields",
      };
      serviceMemberMeta
        .read(data)
        .then((resp) => {
          //console.log("update number_limit_connect", resp);
          this.finds = resp;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    exportData() {
      this.loading = true;
      const filters = {};
      if (this.CourseSelectedFilter !== "") {
        filters.course = this.CourseSelectedFilter.id;
      }
      if (this.actionClassesSelectedFilterArray.length !== 0) {
        filters.course_classes = this.actionClassesSelectedFilterArray;
      }
      if (this.actionSignaturesSelectedFilterArray.length !== 0) {
        filters.signatures = this.actionSignaturesSelectedFilterArray;
      }
      if (this.searchMember !== "" && this.searchMember !== null) {
        filters.search_string = this.searchMember;
      }
      const minimum_global_progress = this.leadscore[0];
      const maximum_global_progress = this.leadscore[1];
      let data = {
        id: "/export",
        siteId: this.currentSiteId,
        email: this.email,
        course: filters.course,
        course_classes: filters.course_classes,
        signatures: filters.signatures,
        search_string: filters.search_string,
        minimum_global_progress,
        maximum_global_progress,
      };
      serviceMember
        .postID(data)
        .then((resp) => {
          this.$bvToast.toast(`A exportação será enviada ao e-mail ${this.currentSite.currentMember.email} em alguns instantes`, {
            title: "Exportação de membros",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast("Ocorreu um erro ao exportar.", {
            title: "Erro",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleScrollClass(event) {
      const multiselectElement = event.target;

      if (
        (multiselectElement.scrollHeight - multiselectElement.scrollTop) <= (multiselectElement.clientHeight + 1)
        &&
        this.pageActualClass < this.lastPageClass
      ) {
      
        this.pageActualClass++;
     
        this.getAllClass("push");
      }
    }
  },
  mounted() {
    this.getManualRegistrationLimit();
    this.getMembers();
    this.getCurrentSiteAndMember();
    this.getAllProducts();
  },
};
</script>

<style lang="scss" scoped>
fieldset[disabled] .multiselect {
  pointer-events: none;
}
.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: #41b883;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__spinner:before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}
.multiselect__spinner:after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}
.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 16px;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}
.multiselect * {
  box-sizing: border-box;
}
.multiselect:focus {
  outline: none;
}
.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
  opacity: 0.6;
}
.multiselect--active {
  z-index: 50;
}
.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active .multiselect__select {
  transform: rotate(180deg);
}
.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 0 0 0 5px;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}
.multiselect__input:-ms-input-placeholder {
  color: #35495e;
}
.multiselect__input::placeholder {
  color: #35495e;
}
.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}
.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}
.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}
.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px;
}
.multiselect__tags-wrap {
  display: inline;
}
.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.multiselect__tag-icon:after {
  content: "\D7";
  color: #266d4d;
  font-size: 14px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff;
}
.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 30px 0 12px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}
.multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
}
.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: "";
}
.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
.multiselect--active .multiselect__placeholder {
  display: none;
}
.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}
.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}
.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}
.multiselect__content::webkit-scrollbar {
  display: none;
}
.multiselect__element {
  display: block;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}
.multiselect__option--highlight {
  background: #41b883;
  outline: none;
  color: #fff;
}
.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #41b883;
  color: #fff;
}
.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700;
}
.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}
.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}
.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}
.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}
.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}
.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}
.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}
.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}
[dir="rtl"] .multiselect {
  text-align: right;
}
[dir="rtl"] .multiselect__select {
  right: auto;
  left: 1px;
}
[dir="rtl"] .multiselect__tags {
  padding: 8px 8px 0 40px;
}
[dir="rtl"] .multiselect__content {
  text-align: right;
}
[dir="rtl"] .multiselect__option:after {
  right: auto;
  left: 0;
}
[dir="rtl"] .multiselect__clear {
  right: auto;
  left: 12px;
}
[dir="rtl"] .multiselect__spinner {
  right: auto;
  left: 1px;
}
@keyframes spinning {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}

.form-group.positionMultiselect div.multiselect--active:first-child {
  position: absolute;
  width: 90%;
}

.spaceMembersMobile {
  width: 100% !important;
  padding: 130px 10px 0px 27px !important;
  .table tr.row-table-body {
    line-height: 40px !important;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
}

.divFilter {
  margin-top: 40px;
  display: grid;
  gap: 22px;
  column-gap: 65px;
  grid-template-columns: 1fr 1fr;
  label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 20px;
    color: var(--fontcolor);
    display: flex !important;
  }
  label::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid var(--maincolor);
    background: var(--maincolortrans);
    display: block;
    margin-right: 15px;
    margin-top: 4px;
  }
  .item:hover {
    transform: scale(1.05);
    transition: all 0.5s;
  }
  .item {
    cursor: pointer;
    .multiselect--active {
      transform: scale(1.05);
      transition: all 0.5s;
    }
    .multiselect__select {
      height: 51px !important;
    }
    .multiselect__tags {
      height: 50px;
      border: solid 1px var(--bordercolor) !important;
    }
    .multiselect__placeholder {
      padding-top: 7px !important;
      font-family: Montserrat !important;
      font-weight: normal !important;
      color: #81858e !important;
      font-size: 14px !important;
      transition: 0.3s !important;
      padding-left: 18px !important;
    }
    .multiselect__single {
      margin-bottom: 8px;
      font-family: Montserrat;
      font-weight: normal;
      color: #81858e;
      font-size: 14px;
      transition: 0.3s;
      padding-top: 6px;
      padding-left: 18px !important;
    }
    .multiselect__input,
    .multiselect__single {
      margin-bottom: 8px;
      font-family: Montserrat;
      font-weight: normal;
      color: #81858e !important;
      font-size: 14px;
      transition: 0.3s;
      padding-top: 6px;
      padding-left: 18px !important;
    }
    .multiselect__tag {
      margin-top: 5px;
      margin-left: 12px;
      background: var(--maincolor) !important;
    }
    .multiselect__tag-icon:after {
      color: var(--maincolorn) !important;
    }
    .multiselect__option--highlight {
      background: var(--maincolor) !important;
    }
    .multiselect__option--highlight:after {
      background: var(--maincolor) !important;
    }
    .multiselect__option--selected.multiselect__option--highlight {
      background: #ff6a6a !important;
    }
    .multiselect__option--selected.multiselect__option--highlight:after {
      background: #ff6a6a !important;
    }
    .multiselect__option span {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
    }
  }
}

.openFilter {
  height: 40px;
  background: rgba(33, 51, 210, 0.05);
  border-radius: 5px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.2px;
  color: #2133d2;
  border: none;
  margin-top: 38px;
  white-space: nowrap;
  padding: 0 15px;
}

.openFilter:hover {
  transform: scale(1.05);
  transition: all 0.3s;
}

.openFilter.button_disabled {
  opacity: 0.7;
  color: rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.12);
  border: none;
}

.spaceMembers {
  width: 100%;
  padding: 0 44px 0 48px;
  padding-top: 125px;
  .vue-slider-dot-tooltip-inner.vue-slider-dot-tooltip-inner-top {
    background-color: #ffffff00 !important;
    box-shadow: none !important;
    margin-bottom: -5px;
  }
  .vue-slider-dot-tooltip-inner-top::after {
    border-top-color: #ffffff00 !important;
  }
  .vue-slider-process {
    background-color: var(--maincolor) !important;
  }
  .vue-slider-dot-handle {
    border: 2px solid #e9ecef !important;
  }
  span.vue-slider-dot-tooltip-text {
    color: var(--fontcolor) !important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
  }
  .spaceFilters {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .spaceSearch {
      display: grid;
      grid-template-columns: 4fr 1fr 1fr 1fr 1fr 1fr;
      gap: 30px;
    }
    .spaceSearchMobile {
      display: grid;
      grid-template-columns: 1fr;
    }
    .inputSearch {
      position: relative;
      margin-top: 20px;
      width: 28em;
      top: 10px;
      img {
        cursor: pointer;
      }
    }
    .inputSearchMobile {
      position: relative;
      margin-top: 10px;
      width: 98%;
      top: 10px;
      img {
        cursor: pointer;
      }
    }
    .input-busca {
      width: 100%;
      height: 50px;
      background: #ffffff;
      border: solid 1px var(--bordercolor);
      border-radius: 5px;
      padding: 20px 30px;
      font-family: Montserrat;
      font-weight: normal;
      color: #81858e;
      font-size: 14px;
      transition: 0.3s;
    }
    .input-busca:hover,
    .input-busca:focus {
      // box-shadow: 0 6px 12px rgba(30, 60, 90, 0.2);
      transform: scale(1.05);
      transition: all 0.5s;
    }
    .search {
      position: absolute;
      right: 25px;
      top: 19px;
      width: 14.7px;
      height: 14.7px;
      z-index: 999;
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }
  }
  .spaceProgress {
    margin-top: 12px;
  }
  .fundoModal {
    position: fixed;
    background: var(--fundomodal) !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999999999999;
  }
  .contentAdd {
    z-index: 9999999999999999999;
    position: absolute;
    padding: 30px;
    right: 0;
    top: 25vh;
    width: 303px;
    height: 345px;
    background: var(--backgroundcolor) !important;
    border: 1px solid var(--bordercolor);
    box-sizing: border-box;
    border-radius: 3px;
    .close {
      cursor: pointer;
    }
    .space {
      margin-top: 40px;
      margin-bottom: 40px;
      input {
        border: 1px solid var(--bordercolor);
        box-sizing: border-box;
        border-radius: 5px;
        height: 40px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #6d767e;
        overflow: hidden !important;
        resize: none !important;
        background: white;
      }
      select {
        border: 1px solid var(--bordercolor);
        box-sizing: border-box;
        border-radius: 5px;
        height: 40px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #6d767e;
        overflow: hidden !important;
        resize: none !important;
      }
      input:hover,
      select:hover,
      textarea:hover,
      input:focus,
      select:focus,
      textarea:focus {
        border: 1px solid var(--maincolor);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--fontcolor2);
      }
      label {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: var(--fontcolor);
      }
      .btn-salvar {
        background: var(--maincolor);
        border-radius: 5px;
        border: none;
        width: 146px;
        font-family: Montserrat;
        color: #ffffff;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        height: 40px;
      }
    }
    .title {
      font-family: "Montserrat Alternates";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--maincolor);
    }
  }
  .hide {
    display: none !important;
  }
  .lineDivMember {
    width: 100%;
    height: 0.5px;
    background: #e5e5e5;
    transform: matrix(1, 0, 0, -1, 0, 0);
    margin-top: 20px;
  }
  .textTotalMember {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #81858e;
    margin-top: 20px;
    margin-bottom: -40px;
  }
  .table {
    margin-top: 55px;
    th {
      border: none !important;
      font-family: Montserrat !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 14px !important;
      letter-spacing: 0.2px !important;
      color: var(--fontcolor) !important;
    }
    th div {
      color: var(--fontcolor) !important;
      padding-bottom: 8px;
    }
    thead tr {
      background: #e4e4e414;
    }
    tr.row-table-body {
      line-height: 50px !important;
    }
    td {
      border: none !important;
      // border-bottom: 1px solid var(--bordercolor) !important;
    }
    .table thead th {
      border: none !important;
    }
    .text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: 0.2px;
      color: #81858e;
    }
    .flex {
      display: flex;
      .deletar {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.2px;
        color: #ff0c37;
        cursor: pointer;
      }
      .editar {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.2px;
        color: #81858e;
        margin-right: 15px;
        cursor: pointer;
      }
      .editar:hover {
        transform: scale(1.05);
        transition: all 0.5s;
      }
    }
  }
  .spaceTable {
    // margin-top: 60px;
    .flex {
      display: flex;
      .deletar {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.2px;
        color: #ff0c37;
        cursor: pointer;
      }
      .editar {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.2px;
        color: var(--maincolor);
        margin-right: 15px;
        cursor: pointer;
      }
    }
    .nameTable {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      color: var(--fontcolor);
    }
    .userPic2 {
      width: 35px;
      height: 35px;
      border-radius: 100%;
      background: rgba(129, 133, 142, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        display: flex;
        align-items: center;
        color: #ffffff;
      }
    }
    .userPic {
      width: 35px;
      height: 35px;
      border-radius: 100%;
      img {
        width: 100%;
        border-radius: 100%;
      }
    }
    .textTable {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #81858e;
    }
  }
  .headerFlex {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .title {
      font-family: "Montserrat Alternates";
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      color: var(--fontcolor);
    }
  }
  .paginate {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 60px;
    margin-top: 40px;
    .text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
      cursor: pointer;
    }
    .text:hover {
      color: #00e4a0;
    }
    .text:hover svg path {
      stroke: #00e4a0 !important;
    }
  }
}

#modal-member {
  p.ativar {
    color: var(--maincolor) !important;
    font-weight: 600 !important;
    margin-left: 15px;
    cursor: pointer;
  }
  p.desativar {
    color: #ff0c37 !important;
    font-weight: 600 !important;
    margin-left: 15px;
    cursor: pointer;
  }
  .allSpaceModalMember {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 0px 30px 30px 30px;
  }
  .allSpaceModalMemberMobile {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .columMember {
    width: 100%;
    .gridItensHeader {
      display: grid;
      grid-template-columns: 3fr 2fr 2fr 1fr;
      margin-bottom: 30px;
      div#dropdown-actions-member button {
        height: 40px;
        background: #2133d20d;
        border-radius: 5px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.2px;
        color: #2133d2;
        border: none;
        width: 155px;
      }
      .subMenu a {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        color: var(--fontcolor);
        line-height: 25px;
        padding: 11px 36px;
      }
      .subMenu a:hover {
        background: var(--backgroundcolor);
      }
    }
    .gridItensHeaderMobile {
      display: grid;
      grid-template-columns: 1fr;
      margin-bottom: 30px;
      gap: 29px;
    }
    .titlePermicao {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
    }
    .textPermicao {
      font-family: Montserrat;
      font-style: normal;
      margin-top: 10px;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: 0.2px;
      color: #81858e;
    }
    .btnVoltar {
      background: #f7f7f7;
      border: 1px solid #f7f7f7;
      box-sizing: border-box;
      border-radius: 5px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #81858e;
      width: 150px;
      height: 45px;
    }
    .btnRemoveLimite {
      background: #2133d20d;
      box-sizing: border-box;
      border-radius: 5px;
      margin-left: 10px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #2133d2;
      height: 45px;
      border: none;
    }
    .textLimiteLabel {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
    }
    .notPadding {
      padding: 0 !important;
    }
    .heightToListCouses {
      height: 36vh;
      overflow: auto;
      padding: 0px 12px 12px 0;
    }
    .heightToListCouses::-webkit-scrollbar {
      background-color: #fff;
      width: 2px;
    }

    .heightToListCouses::-webkit-scrollbar-track {
      background-color: #c7c7c7;
    }

    .heightToListCouses::-webkit-scrollbar-thumb {
      background-color: #4d4d4d52;
      border-radius: 31px;
    }

    .heightToListCouses::-webkit-scrollbar-button {
      display: none;
    }
    .actionsBtn {
      display: flex;
      .editar {
        height: 40px;
        background: rgba(33, 51, 210, 0.05);
        border-radius: 5px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.2px;
        color: #2133d2;
        border: none;
        margin-right: 20px;
      }
      .delete {
        height: 40px;
        background: rgba(255, 12, 55, 0.05);
        border-radius: 5px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.2px;
        color: #ff0c37;
        border: none;
      }
    }
    .spaceListCourse2 {
      display: grid;
      grid-template-columns: 3fr 2fr 1fr 1fr 2fr 1fr;
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        letter-spacing: 0.2px;
        color: var(--fontcolor);
      }
      .spaceProgress {
        margin-top: 3px;
        cursor: pointer;
      }
    }
    .spaceListSignature2 {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr;
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        letter-spacing: 0.2px;
        color: var(--fontcolor);
      }
    }
    .spaceListCourse2Mobile {
      display: grid;
      grid-template-columns: 2fr 1fr;
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        letter-spacing: 0.2px;
        color: var(--fontcolor);
      }
      .spaceProgress {
        margin-top: 3px;
      }
    }
    .spaceListCourse {
      display: grid;
      grid-template-columns: 3fr 2fr 1fr 1fr 2fr 1fr;
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: var(--fontcolor);
      }
    }
    .spaceListQuizz2 {
      display: grid;
      grid-template-columns: 4fr 4fr 4fr 2fr;
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        letter-spacing: 0.2px;
        color: var(--fontcolor);
      }
      .spaceProgress {
        margin-top: 3px;
        cursor: pointer;
      }
    }
    .spaceListQuizz {
      display: grid;
      grid-template-columns: 4fr 4fr 4fr 2fr;
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: var(--fontcolor);
      }
    }
    .spaceListSignature {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr;
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: var(--fontcolor);
      }
    }
    .spaceListCourseMobile {
      display: grid;
      grid-template-columns: 2fr 1fr;
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: var(--fontcolor);
      }
    }
    .buttonAdd {
      width: 100%;
      button {
        padding: 20px 23px;
        background: var(--maincolor);
        border: none;
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 5px;
        font-size: 15px;
        color: #fff;
        font-weight: 600;
        font-family: Montserrat;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s ease;
        outline: none;
      }
    }
    .titleList {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      color: var(--fontcolor);
    }
    .spaceLeft {
      margin-left: 30px;
    }
    .flex {
      display: flex;
    }
    .email {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #81858e;
    }
    .email2 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: var(--maincolor);
    }
    .name {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
    }
    .picProfile {
      width: 50px;
      height: 50px;
      background: rgba(129, 133, 142, 0.2);
      border: 1px solid #81858e;
      box-sizing: border-box;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        border-radius: 100%;
      }
      span {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        display: flex;
        align-items: center;
        color: #ffffff;
      }
    }
  }
  header {
    border: none !important;
  }
}

#modal-new-import {
  z-index: 99999999999;
  .modal-header {
    border: none;
  }
  .allContentModal {
    padding: 0px 10px;
    margin: 0 auto;
    display: block;
    text-align: -webkit-center;
    svg {
      g {
        path {
          fill: var(--maincolor) !important;
        }
      }
      .item {
        stroke: var(--maincolor) !important;
      }
    }
  }
  .spaceText2 {
    margin-bottom: 20px;
  }
  .spaceText1 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .text1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.2px;
    color: var(--fontcolor);
    text-align: center;
  }
  .text2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #818181;
  }
  .button {
    background: #000000;
    width: 215px;
    height: 55px;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #ffffff;
    border: none;
  }
}

.spaceMembers .table th .custom-control.custom-control-inline.custom-checkbox {
  position: relative;
  top: 9px;
}

.spaceMembers
  .table
  tr.row-table-body
  .custom-control.custom-control-inline.custom-checkbox {
  position: relative;
  top: 5px;
}

#modal-copy {
  header {
    border: none !important;
  }
  .modalCopy {
    padding: 0 10px;
    margin-top: -15px;
    .footer {
      margin-top: 60px;
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      margin-bottom: 20px;
      .closeAssoc {
        background: var(--backgroundcolor);
        border: 1px solid var(--fontcolor);
        border: none;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: var(--fontcolor);
        width: 155px;
        height: 45px;
      }
      .closeAssoc:hover {
        opacity: 1;
      }
      .confirm {
        background: var(--maincolor);
        border: 1px solid var(--maincolor);
        box-sizing: border-box;
        box-shadow: 0px 4px 10px var(--maincolortrans);
        border-radius: 5px;
        width: 175px;
        height: 45px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        color: #ffffff;
      }
    }
    .contentSelect {
      width: 60%;
    }
    label {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: var(--fontcolor);
    }
    .content {
      display: grid;
      grid-template-columns: 3fr 2fr;
      .text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        color: var(--fontcolor);
      }
      img {
        position: relative;
        left: 3vw;
        top: -20px;
      }
    }
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      display: flex;
      align-items: center;
      color: var(--fontcolor);
      margin-bottom: 20px;
    }
  }
}

.mensageToAll {
  width: 100%;
  background: #cacaca3f;
  height: 45px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  position: relative;
  top: 50px;
  align-items: center;
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: var(--fontcolor);
    margin: inherit;
    button {
      height: 40px;
      background: #ffffff00;
      border-radius: 5px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.2px;
      color: var(--maincolor);
      border: none;
    }
  }
}

.mainColor {
  background: var(--maincolor) !important;
}

.extra {
  input,
  textarea,
  select {
    border: 1px solid var(--bordercolor);
    box-sizing: border-box;
    border-radius: 5px;
    height: 55px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    overflow: hidden !important;
    resize: none !important;
  }
  input:hover,
  select:hover,
  textarea:hover,
  input:focus,
  select:focus,
  textarea:focus {
    border: 1px solid var(--maincolor);
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }
  label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor);
  }
}
</style>
